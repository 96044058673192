/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Jan 15, 2018, 4:41:04 PM
    Author     : jhewitt
*/
.ReactVirtualized__Grid
{
outline: none!important;
}
.withdrawal p{
    text-align: center;
}
.withdrawal .heading{
    font-weight: bold;
    font-size: 1.3em;
}

.auto-investor-overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1032;
    display: flex;
    justify-content: center;
    align-items: center;

}

.auto-investor-modal{
    max-height:80%;
    position: absolute;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    max-width: 60%;
}

.table-input
{
    width: inherit !important;
}

.sticky-head
{
  position: sticky;
  top: 50px;
  background-color: white;
}

.circle-words
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 3px;
    border-radius: 50%;
    border-color:rgb(87, 188, 144);
    border-style: solid;
    text-align: center;
    width: 150px;
    height:150px;
    padding:10px;
}
.withdrawal > button{
    margin:auto;
    display: block;
}
.rounded-button
{
    border-radius: 100px;
}
.account-list
{
    position:relative;
    left:0px;
    transition: left 250ms,opacity 250ms;
    opacity:1;

}
.header
{
    text-align: center;
}
.card-body-list
{
    padding:1rem;
}
.list-item
{
    cursor:pointer;
}
.error
{
    color:red;
}

.btn-vision
{
    color:#fff;
    background-color: rgb(87, 188, 144);
    border-color: rgb(87, 188, 144);
}

.col-margin
{
    margin-bottom: 0.5rem;
}

.btn-viz
{
   margin:2px;
}
.navbar .nav-link, .navbar-brand {
    color: whitesmoke;
}
.star-checked{
        color:#57bc90;
}
body{
    padding-top: 3.5rem ;
}
.login_error
{
    color:red;
    font-size: large;
    margin: 20px;
}
.card-body{
    margin-top: 10px;
}

#description {
margin-bottom: 10px
}

#rating {
margin-bottom: 10px
}


.loader
{
    position:absolute;
    top:50%;
    z-index: 2;

}

.loader {
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
}
.loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #57bc90;
    animation: loading 4s linear infinite;
}

@keyframes loading {
    50% {width: 10%;}
    70% {width: 30%;}
    80% { left: 30%;width:40%}
    95% {left: 50%;width:50%}
    100%{left:80%;}

}





/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {

    .list-image
    {
        height:300px;
    }
    .details-form
    {
        margin:1rem;
    }

    .auto-investor-modal{
        max-height:100%;
        position: absolute;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        max-width: 100%;
    }

    .additional-details
    {
        flex-direction: column;
        align-items: center;
    }

    .hero-description
    {
        max-width: 80%;
        font-size: 1.25rem;
        font-weight: 300;
        margin-bottom: 5rem;
    }
    .info-row
    {
        margin-top: 1rem;
    }

    .info-button
    {
        margin-bottom: 1rem;
    }
    .mobile-loan-card
    {
        max-width: 40%;
    }

    .no-content
    {
        max-width: 100%;
    }


    .desktop_only
    {
        display:none;
    }

    .horizontalScroll{
        width: 100%;
        transform: translateX(0px);

    }
    .loginForm
    {
        background-color: white;
        width: 100%;
        padding: 12px;
    }
    .loginBackground
    {
        display: none;
    }

    .popularHome
    {
        display:inline-block;
        width: 350px;
        height: 200px;
        margin-bottom: 10px;
        
    }

    .details-description{

        width: 90%;
        margin: auto;
    }

    .detail-list-image
    {
    width: 100%;
    height: auto;
        margin-bottom:10px ;
    }


}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .list-image
    {
        height:400px;
    }
    .additional-details
    {
        flex-direction: row;
        align-items: center;
        border: hidden;
    }

    .amortization-caption
    {
        border:hidden;
        flex-direction: row;
    }

    .amortization-table-header
    {
        display: none;
    }


    .details-form
    {
        min-width: 30rem;
        margin:1rem;
    }

    .info-row
    {
        margin-top: 5rem;
    }

    .hero-description
    {
        max-width: 50%;
        font-size: 1.25rem;
        font-weight: 300;
        margin-top: 2rem;
    }
    
    .admin-search
    {
        width:50%;
        margin:auto;
    }

    .single-image
    {
        width:50%;
    }
    .lead{
        max-width: 60%;
        text-align:center;
    }

    .details
    {
        width: 50%;
    }

    .home_video
    {
        width:70%;
        margin:auto;
    }

    .no-content
    {
        max-width: 40%;
        /*Added this because of the bottom on error images*/
        margin-bottom: 1rem;
    }

    .nav-right
    {
        margin-left:auto;
    }

    .details-description{
        width: 50%;
        margin: auto;
    }

    .detail-list-image
    {
        width: 250px;
        height: 187px;
        margin: 10px;
    }

    .mobile_only
    {
        display:none;
    }

    .main_card{
        width: 70%;
        margin:auto;
    }
    .loginBackground
    {

        width: 50%;
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

    }

    .loginForm
    {
    padding: 40px; height: auto;
    vertical-align: top; width: 50%; background-color: white;
    }

    .popularHome
    {
        display: inline-block;
        width: 350px;
        height: 200px;
        margin: 10px;
    }
    .horizontalScroll{
        white-space: nowrap;
        transition: transform 1s;
        margin: 40px 0px;
        position: relative;

    }

    .scrollerRight
    {
        position: relative;
        right:0px;
        align-self: center;
        margin:20px;
        z-index: 1;
    }

    .scrollerLeft
    {
        position: relative;
        left:0px;
        align-self: center;
        margin:20px;
        z-index: 1;

    }
    .scrollContainer
    {
        position: relative;
        overflow: hidden;
        display:flex;
    }
    .scroll-button
    {
        border-radius: 50%;
        height: 30px;
        width:30px;
        text-align: center;
        box-shadow: 0px 0px 2px rgb(87, 188, 144);;
        vertical-align: middle;
        line-height: 30px;
    }

    .confirm-page{
        width:45%;
        height:auto;
        padding:40px;
        margin:auto;
    }

}

.dropbtn {
    background-color: #3498DB;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
    background-color: #2980B9;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    top: 0px;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 4;
}

/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    z-index: 4;
    background-color: #ddd}

.dropdownOverlay{
    top:0px;
    left:0px;
    display:none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}

.shadow {
    box-shadow: 10px 10px 5px grey;
}


.fa-eye
{
    cursor: pointer;
}


